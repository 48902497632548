<template>
  <div>
    <VTitle :title="$t('app.add_department')" class="section-title" />

    <Form
      :is-loading="isLoading"
      @click:cancel="onClickCancel"
      @click:save="onClickSave"
    />
  </div>
</template>

<script>
import VTitle from "@/components/VTitle";
import Form from "@/views/administration/departments/Form";
import useDepartment from "@/composables/useDepartment";
import useCreate from "@/composables/useCreate";
import { useI18n } from "vue-i18n";
import { computed } from "vue";

export default {
  components: {
    VTitle,
    Form
  },
  setup() {
    // MISC
    const { t } = useI18n();

    // COMPUTED
    const documentTitle = computed(() => {
      return `${t("app.add")} - ${t("app.departments", 2)} - ${t(
        "app.administration"
      )}`;
    });

    // CUSTOM COMPOSABLES
    const { endpoint, route } = useDepartment();
    const { isLoading, onClickSave, onClickCancel } = useCreate(
      endpoint,
      route
    );

    return {
      documentTitle,
      isLoading,
      onClickSave,
      onClickCancel
    };
  }
};
</script>
